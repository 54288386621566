import React from "react"
import styled from "styled-components"

import PageWrapper from "../components/PageWrapper"

import GenuineLeatherPhotoBook from "../images/product/genuine-leather-photo-book.jpg"
import PrintedHardCoverPhotoBook from "../images/product/printed-hard-cover-photo-book.jpg"

const ProductPage = () => (
  <PageWrapper>
    <Header>
      <h1>Product Specifications</h1>
    </Header>
    <Options className="bottom-padding">
      <p className="title">Printed Hard Cover Photo Book</p>
      <div className="grid">
        <div>
          <img
            src={PrintedHardCoverPhotoBook}
            alt="Printed Hard Cover Photo Book"
          />
        </div>
        <div>
          <p>Cover: Printed cover matt finish</p>
          <p>Size: A4 Landscape 280 x 210mm</p>
          <p>Pages: 40 pages</p>
          <p>Binding: Standard Binding</p>
          <p>Price: R 1 058</p>
          <p>Delivery: included</p>
        </div>
      </div>
    </Options>

    <Options>
      <p className="title">Genuine Leather Photo Book</p>
      <div className="grid">
        <div>
          <img src={GenuineLeatherPhotoBook} alt="Genuine Leather Photo Book" />
        </div>
        <div>
          <p>Cover: Genuine leather with embossed title</p>
          <p>Cover colour options: Black, Brown and Oxblood</p>
          <p>Size: A4 Landscape 280 x 210mm</p>
          <p>Pages: 40 pages</p>
          <p>Binding: Layflat Binding</p>
          <p>Price: R 1 999</p>
          <p>Delivery: included</p>
        </div>
      </div>
    </Options>
  </PageWrapper>
)

const Header = styled.div`
  margin: 50px 0;
  h1 {
    font-size: 1.5rem;
    font-family: ${props => props.theme.font.galanoBold};
    color: ${props => props.theme.color.grey};
    text-align: center;
  }
  @media (min-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }
  }
`

const Options = styled.div`
  &.bottom-padding {
    padding-bottom: 30px;
  }
  img {
    max-width: 100%;
    margin: 10px;
  }
  p {
    font-family: ${props => props.theme.font.galanoMed};
    padding-top: 10px;
  }
  .title {
    font-size: 2rem;
    text-align: center;
    padding-bottom: 20px;
    font-family: ${props => props.theme.font.saturdayRock};
  }
  @media (min-width: 768px) {
    .title {
      font-size: 4rem;
      text-align: center;
    }
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
`
export default ProductPage
